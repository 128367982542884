<template>
  <v-btn v-if="!currentUser.googleSynced && calendars[calendar.GOOGLE_EVENTS]" @click="handleAuthClick">
    {{ $t('layout.syncWithGoogle') }}
  </v-btn>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {calendar} from 'constants';

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
// TODO: define scope based on user role
const SCOPES = 'https://www.googleapis.com/auth/calendar';

export default {
  data() {
    return {
      calendar,
      api: undefined
    };
  },
  computed: mapGetters(['currentUser', 'calendars']),
  methods: {
    ...mapActions(['authenticateGoogleAccount']),
    initClient() {
      const vm = this;
      vm.api.client.init({
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES
      });
    },
    handleAuthClick(event) {
      Promise.resolve(this.api.auth2.getAuthInstance().currentUser.get().grantOfflineAccess())
        .then(this.login);
    },
    async login(authcode) {
      this.authenticateGoogleAccount(authcode).then((statusCode) => {
        this.$emit('synced', statusCode === 200);
      });
    }
  },
  created() {
    if (!this.currentUser.googleSynced) {
    // eslint-disable-next-line no-undef
      this.api = gapi;
      this.api.load('client:auth2', this.initClient);
    }
  }
};
</script>
