<template>
  <v-dialog v-model="modal.show" width="550">
    <v-card>
      <v-card-actions class="row pb-0" v-if="!showForm && canCancel" >
        <v-spacer></v-spacer>
        <v-btn class="mr-2" icon small @click="showCancelForm">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-title class="row pt-0">
        <v-icon :color="calendar.color || 'primary'" class="col-1 pb-0">mdi-checkbox-blank</v-icon>
        <h3 class="col-11 pb-0">{{ summary }}</h3>
        <h4 class="col-11 offset-1 grey--text text--darken-2 py-0">
          {{ moment(event.start).format('dddd, MMMM Do YYYY') }}
          <span v-if="!event.allDay && (event.extendedProps && !event.extendedProps.multipleDays)">
            . {{ moment(event.start).format('hh:mm A') }} - {{ moment(event.end).format('hh:mm A') }}
          </span>
          <span v-if="event.extendedProps && event.extendedProps.multipleDays">
            - {{ moment(event.end).format('dddd, MMMM Do YYYY') }}
          </span>
        </h4>
      </v-card-title>
      <v-card-text class="row py-0">
        <div v-if="googleEvent" class="col-11 offset-1">
          <v-btn v-if="googleEvent.link" :href="googleEvent.link" target="_blank" depressed small color="primary">
            {{ $t('layout.joinMeeting') }}
          </v-btn>
          <p>{{ googleEvent.link }}</p>
          <p class="mb-1" v-if="googleEvent.location">
            <v-icon small>mdi-map-marker</v-icon>{{ googleEvent.location }}</p>
          <p class="mb-1" v-if="googleEvent.organizer">
            <v-icon small>mdi-calendar</v-icon>
            {{ $t('models.calendar.attributes.createdBy')}}: {{ googleEvent.organizer.email }}
          </p>
        </div>
        <div v-else-if="canCancel" class="col-11 offset-1 text-right">
          <app-form :fields="cancellationFields" v-if="showForm"
            :submit-button="submitButton" :cancelButton="cancelButton"
            @submitted="cancelLeaveDate" @canceled="showForm = false"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {calendar} from 'constants';
import moment from 'moment';
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      moment,
      cancellationFields: {
        note: {
          type: 'textarea',
          key: 'note',
          value: '',
          rules: [{name: 'required'}],
          label: this.$t('models.leaveRequest.attributes.note'),
          classes: 'col-12 py-0'
        }
      },
      submitButton: {
        label: this.$t('actions.save')
      },
      cancelButton: {
        label: this.$t('actions.cancel')
      },
      showForm: false
    };
  },
  props: {
    modal: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'currentUser']),
    summary() {
      if (this.calendar.key === calendar.BIRTHDAYS) {
        return this.$t('models.calendar.events.birthday', {userName: this.event.title});
      } else if (this.calendar.key === calendar.WORK_ANNIVERSARIES) {
        return this.$t('models.calendar.events.workAnniversary', {userName: this.event.title});
      } else if (this._.startsWith(this.calendar.key, calendar.LEAVES)) {
        return this.$t('models.calendar.events.leave', {
          userName: this.event.title,
          leaveName: this.event.extendedProps.details.leave.name,
          leaveUnit: this.$t(`models.leaveRequest.attributes.units.${this.event.extendedProps.details.unit}`)
        });
      } else {
        return this.event.title;
      }
    },
    googleEvent() {
      return this.calendar.key === calendar.GOOGLE_EVENTS ? this.event.extendedProps.details : null;
    },
    canCancel() {
      return this.calendar.key === calendar.LEAVES && (this.currentUser.theOnlyAdmin ||
        (this.isAdmin && this.currentUser.id !== this.event.extendedProps.details.user.id));
    }
  },
  methods: {
    ...mapActions(['manageLeaveRequest', 'removeEventFromCalendar']),
    showCancelForm() {
      this.showForm = !this.showForm;
      this.cancellationFields.note.value = '';
    },
    cancelLeaveDate() {
      this.loading = true;
      const data = {
        id: this.event.id,
        form: {
          canceled_dates: [moment(this.event.start).format()],
          note: this.cancellationFields.note.value
        }
      };
      this.manageLeaveRequest(data).then((status) => {
        if (this.successCode(status)) {
          this.event.remove();
          this.modal.show = false;
        }
        this.loading = false;
      });
    }
  },
  watch: {
    'modal.show': function() {
      this.showForm = false;
      this.cancellationFields.note.value = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card {
  overflow: hidden !important;
}
</style>
