<template>
  <v-container fluid class="px-lg-5">
    <calendar/>
  </v-container>
</template>

<script>
import Calendar from 'components/events/Calendar';

export default {
  components: {
    Calendar
  }
};
</script>
