<template>
<div>
  <google-auth @synced="syncWithGoogle"></google-auth>
  <div class="row">
    <app-modal :modal="modal" @submit-modal="submitCalendarConfiguration" />
    <event-modal :modal="eventModal" :event="selectedEvent" :calendar="selectedCalendar"/>
    <div class="col-md-9 col-sm-12">
      <FullCalendar :options="calendarOptions" ref="calendar"/>
    </div>
    <div class="col-md-3 col-sm-12">
      <v-date-picker v-model="miniMapDate" :color="this.primaryColor" full-width flat class="py-2"/>
      <h4 class="mt-5 mb-2" color="text">{{ $t('layout.mainMenu.myCalendars') }}</h4>
      <div class="row px-3" v-for="(item, index) in calendars" :key="item.key">
        <v-checkbox class="col-10 my-0 py-0" v-model="item.show" :color="item.color || 'primary'" :label="item.name" />
        <div class="col-2 py-0">
          <v-spacer></v-spacer>
          <v-btn icon small @click="openCalendarConfigurationsModal(item, index)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventModal from 'components/events/Modal';
import GoogleAuth from 'components/events/GoogleAuth';
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import {calendar} from 'constants';

export default {
  components: {
    FullCalendar,
    EventModal,
    GoogleAuth
  },
  data() {
    return {
      primaryColor: localStorage.theme && JSON.parse(localStorage.theme).primaryColor ?
        JSON.parse(localStorage.theme).primaryColor : '#ad3b28',
      calendar,
      moment,
      miniMapDate: new Date().toISOString().substr(0, 10),
      eventModal: {
        show: false
      },
      events: [],
      selectedEvent: {},
      selectedCalendar: {},
      modal: {
        show: false,
        id: '',
        resetForm: false,
        title: this.$tc('models.calendar.entity', 1),
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            rules: [{name: 'required'}],
            label: this.$t('models.calendar.attributes.name'),
            classes: 'col-8 py-0'
          },
          isPrivate: {
            type: 'checkbox',
            key: 'isPrivate',
            value: false,
            name: 'checkbox',
            label: this.$t('models.calendar.attributes.isPrivate'),
            classes: 'col-4 py-0 mt-1'
          },
          color: {
            type: 'colorpicker',
            key: 'color',
            value: '',
            hideCanvas: true,
            showSwatches: true,
            dense: false,
            classes: 'col-12 py-0 mb-5 full-width',
            flat: true
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters(['calendars', 'calendarEvents', 'currentUser', 'isAdmin']),
    calendarApi() {
      return this.$refs.calendar.getApi();
    },
    weekdays() {
      let weekdays = this.currentUser && this.currentUser.office ?
        moment.weekdays().filter((day) => !this.currentUser.office.weekends.includes(day))
          .map((day) => moment(moment().isoWeekday(day)).isoWeekday()) : [0, 1, 2, 3, 4];
      weekdays = this._.replace(weekdays, 7, 0);
      return weekdays;
    },
    calendarOptions() {
      return {
        height: 850,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        nextDayThreshold: '00:00:00',
        displayEventTime: false,
        initialView: 'dayGridMonth',
        dayMaxEventRows: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        customButtons: {
          prev: {
            click: () => {
              this.calendarApi.prev();
              this.fetchEvents();
            }
          },
          next: {
            click: () => {
              this.calendarApi.next();
              this.fetchEvents();
            }
          },
          today: {
            text: 'Today',
            click: () => {
              this.goToDate(new Date());
            }
          }
        },
        eventClick: this.handleEventClick,
        firstDay: this.weekdays[0],
        businessHours: [
          {
            daysOfWeek: this.weekdays,
            startTime: this.currentUser.office ? this.currentUser.office.businessHoursStart : null,
            endTime: this.currentUser.office ? this.currentUser.office.businessHoursEnd : null
          }
        ],
        events: this.events.map((calEvent) => ({
          ...calEvent,
          display: this.calendars[calEvent.groupId] && this.calendars[calEvent.groupId].show ? 'auto' : 'none'
        }))
      };
    }
  },
  methods: {
    ...mapActions(['setLoading', 'getCalendars', 'getEvents', 'updateEvents', 'updateCalendar']),
    fetchEvents() {
      this.setLoading(true);
      this.calendarApi.removeAllEvents();
      const {start, end} = this.calendarApi.currentData.dateProfile.activeRange;
      const options = {start: moment.utc(moment(start)).format(), end: moment.utc(moment(end)).format()};
      this.getEvents({...options, keys: this._.pull(this._.values(this.calendar), this.calendar.GOOGLE_EVENTS)})
        .then(() => {
          this.events = this.calendarEvents;
          this.getEvents({...options, keys: [this.calendar.GOOGLE_EVENTS]}).then(() => {
            this.setLoading(false);
            this.addGoogleEvents();
          });
        });
    },
    addGoogleEvents() {
      this.calendarApi.batchRendering(() => {
        this.calendarEvents.forEach((event) => {
          this.calendarApi.addEvent({
            ...event,
            display: this.calendars[event.groupId] && this.calendars[event.groupId].show ? 'auto' : 'none'
          });
        });
      });
    },
    goToDate(date) {
      this.calendarApi.gotoDate(date);
      this.fetchEvents();
    },
    openCalendarConfigurationsModal(item, index) {
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = item[field.key];
      });
      this.modal.data = {id: item.id, index: index};
      this.modal.show = true;
    },
    submitCalendarConfiguration(data) {
      this.updateCalendar({
        id: this.modal.data.id, key: this.modal.data.index, form: data
      }).then((status) => {
        if (this.successCode(status)) {
          this.modal.show = false;
        }
      });
    },
    syncWithGoogle(isSynced) {
      if (isSynced) {
        this.fetchEvents();
      }
    },
    handleEventClick(e) {
      this.selectedEvent = e.event;
      this.selectedCalendar = this.calendars[e.event.groupId];
      this.eventModal.show = true;
    }
  },
  mounted() {
    if (!this.isAdmin) {
      ['name', 'isPrivate'].forEach((field) => this.modal.fields[field].hidden = true);
    }
    Promise.all([
      this.getCalendars(),
      this.fetchEvents()
    ]);
  },
  watch: {
    miniMapDate(val) {
      const date = new Date(val);
      if (moment(date).month() !== moment(this.calendarApi.getDate()).month() ||
        moment(date).year() !== moment(this.calendarApi.getDate()).year()) {
        this.goToDate(date);
      }
    }
  }
};
</script>

<style lang="scss">

.fc-popover-body {
  height: 300px;
  overflow: auto;
}
  .fc-day-today {
    .fc-daygrid-day-number {
      background-color: #F0EB8D;
      border-radius: 50%;
      width: 29px;
      height: 29px;
      display: flex;
      justify-content: center;
      margin-top: 1.5px;
      align-items: center;
      color: var(--v-highlight);
    }
}
  .fc-non-business{
    background: var(--fc-today-bg-color) !important;
  }
  .fc-day-today{
    background-color: var(--fc-today-bg-color) !important;
  }
  .secondary-color-text {
    .fc-event-title {
      color: var(--v-secondary);
    }
  }
  .fc .fc-button-primary {
    background-color: var(--v-primary);
    border-color: var(--v-primary);
    &:disabled {
      background-color: var(--v-primary);
      border-color: var(--v-primary);
    }
    &:hover,
    &:not(:disabled):active,
    &:not(:disabled).fc-button-active {
      background-color: var(--v-primary);
      border-color: var(--v-primary);
      opacity: 0.8;
      box-shadow: none !important;
    }
  }
  .v-picker__title.primary {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  @media (max-width: 767.98px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: center;
    }
    .fc .fc-toolbar-title {
      font-size: 1.3em;
    }
    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
    .fc .fc-button {
      padding: 0.4em;
    }
  }
</style>
